<template>
  <div>
    <!--改成业务名称 + 触发-->
    <van-nav-bar title="库位拉动业务名" left-text="返回" left-arrow @click-left="back()" />
  </div>
  <div class="d_form">
    <van-form @submit="onSubmit">
      <van-cell-group inset>
        <div class="flex scan">
          <div style="width: 90%;"><van-field v-model="dm" label="地码" /></div>
          <div><van-icon name="scan" size="22" /></div>
        </div>
        <van-field v-model="status1" label="库位状态" readonly />
        <div class="flex scan">
          <div style="width: 90%;"><van-field v-model="dm1" label="工装号" /></div>
          <div><van-icon name="scan" size="22" /></div>
        </div>
        <van-field v-model="jig_type" label="工装类型" readonly />
        <div style="height: 1px; border-top: 1px solid black"></div>
        <div class="row_box">
          <div style="width: 180px">触发类型：库位状态</div>
          <div>触发方式：拉动</div>
        </div>
        <div class="row_box">
          <div style="width: 180px">起始仓库：接驳区</div>
          <div>目标仓库：成品区</div>
        </div>
        <van-collapse v-model="activeName" accordion>
          <van-collapse-item title="物料" name="1">
           <div class="table-container">
             <table class="wide-table">
               <tr>
                 <th>序号</th>
                 <th>物料名称</th>
                 <th>物料编码</th>
                 <th>数量</th>
                 <th>E看板编码</th>
                 <th>供应商编码</th>
                 <th>批次</th>
               </tr>
               <tr v-for="user in list2" :key="user.id">
                 <td>{{ user.id }}</td>
                 <td>{{ user.name }}</td>
                 <td>{{ user.code }}</td>
                 <td>{{ user.number }}</td>
                 <td>{{ user.Ekanban }}</td>
                 <td>{{ user.providerCode }}</td>
                 <td>{{ user.lot }}</td>
               </tr>
             </table>
           </div>
          </van-collapse-item>
        </van-collapse>
        <div style="margin: 16px;">
          <van-button block type="primary" size="small" native-type="submit" @click="flag = !flag" :disabled="flag">
            确定
          </van-button>
        </div>
        <van-tabs v-if="flag" v-model:active="active">
          <van-tab title="扫出">
            <div class="flex scan">
              <div style="width: 88%;"><van-field v-model="sc" label="直接扫出" /></div>
              <div><van-icon name="scan" size="22" /></div>
            </div>
            <div class="list2">
              <div class="table-container">
                <table class="wide-table">
                  <tr>
                    <th>序号</th>
                    <th>物料名称</th>
                    <th>物料编码</th>
                    <th>数量</th>
                    <th>E看板编码</th>
                    <th>供应商编码</th>
                    <th>批次</th>
                  </tr>
                  <tr v-for="user in list2" :key="user.id">
                    <td>{{ user.id }}</td>
                    <td>{{ user.name }}</td>
                    <td>{{ user.code }}</td>
                    <td>{{ user.number }}</td>
                    <td>{{ user.Ekanban }}</td>
                    <td>{{ user.providerCode }}</td>
                    <td>{{ user.lot }}</td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="flex scan">
              <div style="width: 88%;"><van-field v-model="cf" label="拆分看板扫出" /></div>
              <div><van-icon name="scan" size="22" /></div>
            </div>
            <div class="list2">
              <div class="table-container">
                <table class="wide-table">
                  <tr>
                    <th>序号</th>
                    <th>物料名称</th>
                    <th>物料编码</th>
                    <th>数量</th>
                    <th>拆分</th>
                    <th>剩余</th>
                    <th>操作</th>
                  </tr>
                  <tr v-for="user in list3" :key="user.id">
                    <td>{{ user.id }}</td>
                    <td>{{ user.name }}</td>
                    <td>{{ user.code }}</td>
                    <td>{{ user.number }}</td>
                    <td>{{ user.chai }}</td>
                    <td>{{ user.sheng }}</td>
                    <td><van-button type="primary" size="mini">拆分</van-button><van-button type="primary" size="mini">打印</van-button></td>
                  </tr>
                </table>
              </div>
            </div>
          </van-tab>
          <van-tab title="移库">
            <div class="flex scan">
              <div style="width: 88%;"><van-field v-model="yk" label="直接移库" /></div>
              <div><van-icon name="scan" size="22" /></div>
            </div>
            <div class="flex scan">
              <div style="width: 88%;"><van-field v-model="yr" label="移入位置" /></div>
              <div><van-icon name="scan" size="22" /></div>
            </div>
            <div class="list2">
              <div class="table-container">
                <table class="wide-table">
                  <tr>
                    <th>序号</th>
                    <th>物料名称</th>
                    <th>物料编码</th>
                    <th>数量</th>
                    <th>E看板编码</th>
                    <th>供应商编码</th>
                    <th>批次</th>
                  </tr>
                  <tr v-for="user in list2" :key="user.id">
                    <td>{{ user.id }}</td>
                    <td>{{ user.name }}</td>
                    <td>{{ user.code }}</td>
                    <td>{{ user.number }}</td>
                    <td>{{ user.Ekanban }}</td>
                    <td>{{ user.providerCode }}</td>
                    <td>{{ user.lot }}</td>
                  </tr>
                </table>
              </div>
            </div>
            <div class="flex scan">
              <div style="width: 88%;"><van-field v-model="xx" label="拆分看板移库" /></div>
              <div><van-icon name="scan" size="22" /></div>
            </div>
            <div class="flex scan">
              <div style="width: 88%;"><van-field v-model="xx" label="移入位置" /></div>
              <div><van-icon name="scan" size="22" /></div>
            </div>
            <div class="list2">
              <div class="table-container">
                <table class="wide-table">
                  <tr>
                    <th>序号</th>
                    <th>物料名称</th>
                    <th>物料编码</th>
                    <th>数量</th>
                    <th>拆分</th>
                    <th>剩余</th>
                    <th>操作</th>
                  </tr>
                  <tr v-for="user in list3" :key="user.id">
                    <td>{{ user.id }}</td>
                    <td>{{ user.name }}</td>
                    <td>{{ user.code }}</td>
                    <td>{{ user.number }}</td>
                    <td>{{ user.chai }}</td>
                    <td>{{ user.sheng }}</td>
                    <td><van-button type="primary" size="mini">拆分</van-button><van-button type="primary" size="mini">打印</van-button></td>
                  </tr>
                </table>
              </div>
            </div>
          </van-tab>
          <van-tab title="扫入">
            <div class="flex scan">
              <div style="width: 88%;"><van-field v-model="sr" label="直接扫入" /></div>
              <div><van-icon name="scan" size="22" /></div>
            </div>
            <div class="list2">
              <div class="table-container">
                <table class="wide-table">
                  <tr>
                    <th>序号</th>
                    <th>物料名称</th>
                    <th>物料编码</th>
                    <th>数量</th>
                    <th>E看板编码</th>
                    <th>供应商编码</th>
                    <th>批次</th>
                  </tr>
                  <tr v-for="user in list2" :key="user.id">
                    <td>{{ user.id }}</td>
                    <td>{{ user.name }}</td>
                    <td>{{ user.code }}</td>
                    <td>{{ user.number }}</td>
                    <td>{{ user.Ekanban }}</td>
                    <td>{{ user.providerCode }}</td>
                    <td>{{ user.lot }}</td>
                  </tr>
                </table>
              </div>
            </div>
          </van-tab>
        </van-tabs>
      </van-cell-group>
      <div v-if="flag === true" style="margin: 16px;">
        <van-button block type="primary" size="small" native-type="submit">
          确定
        </van-button>
      </div>
      <div style="height: 80px"></div>
    </van-form>
  </div>

</template>
<script setup>
import { useRouter } from "vue-router"
import { ref } from 'vue'
const router = useRouter()
const name = ref('库位拉动A')
const type = ref('库位状态')
const statusName = ref('拉动')
const activeName  = ref('1')
const status = ref(1)
const start = ref('接驳区')
const end = ref('成品区')
const flag = ref(false)
const dm = ref('地码值')
const status1 = ref('空')
const jig = ref('工装号')
const jig_type = ref('类型1')
const customFieldName = {
  text: 'value',
  id: 'id'
}
const active = ref(0);
const columns = [
  { id: 1, value: '推动' },
  { id: 2, value: '拉动' },
]
const statusPicker = ref(false)
const list = ref([])
const list2= ref([
  { id: 1,
    name: '后视镜',
    code: 'hsj-50',
    number: '10',
    status: '有货',
    jig: '01',
    providerCode: '供应商A',
    Ekanban: 'kanban',
    lot: 1
  },
  {  id: 2,
    name: '后视镜',
    code: 'hsj-50',
    number: '10',
    status: '有货',
    jig: '01',
    providerCode: '供应商A',
    Ekanban: 'kanban',
    lot: 1
  }
])
const list3= ref([
  { id: 1,
    name: '后视镜',
    code: 'hsj-50',
    number: '20',
    chai: '10',
    sheng: '10',
    lot: 1
  },
  {  id: 2,
    name: '后视镜',
    code: 'hsj-50',
    number: '20',
    chai: '10',
    sheng: '10',
    lot: 1
  }
])
const listA = ref([
  '1', '2', 3, 4
])
const listC = ref([
  {
    a: '后视镜',
    b: '50',
    c: '10',
    code: 'xxx'
  }
])
const listB = ref([
  '任务1', '任务2'
])
const loading = ref(false)
const finished = ref(false)
function back() {
  router.go(-1);
}
function onSubmit() {
  console.log(123)
}
function checkChange() {

}

</script>
<style scoped>
.flex{
  display: flex;
}
.scan{
  align-items: center;
}
.kan{
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
}

</style>
<style>
.list2 .van-cell__title span{
  font-size: 0.3rem;
}




</style>
